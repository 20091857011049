<template>
  <div>
    <!-- SN Segment Map table -->
    <site-segment-map-tables v-if="store.mmData" :toggleCreateMap="toggleCreateMap" :toggleEditMap="toggleEditMap" :setEditValue="setEditValue" ></site-segment-map-tables>

    <!-- create SN Segment Map modal -->
    <modal :show.sync="modals.createMap"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Create Map</h3>
      <create-site-segment-map-forms class="mm-modal mm-create" :toggleCreateMap="toggleCreateMap" ></create-site-segment-map-forms>
    </modal>

    <!-- edit SN Segment Map modal -->
    <modal :show.sync="modals.editMap"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Edit Map</h3>
      <edit-site-segment-map-forms class="mm-modal mm-create" :toggleEditMap="toggleEditMap" :editValue="editValue" ></edit-site-segment-map-forms>
    </modal>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import SiteSegmentMapTables from 'src/components/Dashboard/Views/Tables/SiteSegmentMapTables.vue'
  import CreateSiteSegmentMapForms from 'src/components/Dashboard/Views/Forms/CreateSiteSegmentMapForms.vue'
  import EditSiteSegmentMapForms from 'src/components/Dashboard/Views/Forms/EditSiteSegmentMapForms.vue'

  export default {
    components: {
      SiteSegmentMapTables,
      CreateSiteSegmentMapForms,
      EditSiteSegmentMapForms,
      Modal,
      [Button.name]: Button,
    },
    data () {
      return {
        store,
        modals: {
          createMap: false,
          editMap: false,
        },
        editValue: {
          site: '',
          site_clean: '',
          status_level: 1,
        },
      }
    },
    methods: {
      setEditValue(obj) {
        const keyArr = Object.keys(obj),
        keyLen = keyArr.length;

        for (let k = 0; k < keyLen; k++) {
          const tmpKey = keyArr[k];

          this.editValue[tmpKey] = obj[tmpKey]
        }
      },
      toggleCreateMap() {
        this.modals.createMap = !this.modals.createMap
      },
      toggleEditMap() {
        this.modals.editMap = !this.modals.editMap
      },
    },
    async mounted () {
      //await store.initMundial()

      //console.log({store})
    }
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
</style>
